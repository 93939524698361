export const DESKTOP_NAV_ITEM = "DesktopNavItem";
export const DESKTOP_SUB_ITEM = "desktopsubitem";

export const WOMEN_NAVIGATION = "pageProductCategoryPageWomen";
export const MEN_NAVIGATION = "pageProductCategoryPageMen";

export const TOGGLE_NAVIGATION_BUTTON = "toggle-navigation-button";
export const FIRST_LEVEL_NAVIGATION_BUTTON = "first-level-navigation-button";

export const MENU_DROPDOWN = "menu-dropdown";
export const SECOND_LEVEL_NAVIGATION_LINK = "second-level-navigation-link";
export const SECOND_LEVEL_NAVIGATION_BUTTON = "second-level-navigation-button";
export const THIRD_LEVEL_NAVIGATION_LINK = "third-level-navigation-link";

export const MOBILE_OPEN_SEARCH_ICON = "mobile-open-search-icon";
