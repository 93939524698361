import AuthenticationActionBar from "@components/auth/auth-action-bar/AuthenticationActionBar";
import { Link } from "@components/cms/link/Link";
import { renderRichTextToComponent } from "@components/cms/rich-text-renderer/renderRichTextToComponent";
import { FooterEmailSubscription } from "@components/layouts/footer/footer-email-subscription/FooterEmailSubscription";
import { CountrySelector } from "@components/ui/country-selector/CountrySelector";
import { SocialMediaWidget } from "@components/ui/social-media-widget";
import { activeSiteHasLiveChat } from "@config/site/site-config";
import type { IFooter } from "@contentful-api/types/contentful";
import { useCountriesSelector } from "@hooks/useCountrySelect";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { clearCustomerServiceIcon } from "@lib/utils/clearCustomerServiceIcon";
import { Text } from "@ui/components/content/text/Text";
import { Divider } from "@ui/components/data-display/divider/Divider";
import { Button } from "@ui/components/forms/button/Button";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { isEmpty } from "lodash-es";
import dynamic from "next/dynamic";
import type { FC } from "react";
import { styles } from "./styles";

const LiveChatScript = dynamic(() => import("@lib/live-chat/LiveChatScript"));
const LiveChat = dynamic(() => import("@components/ui/live-chat/LiveChat"), { ssr: false });

export interface FooterProps {
    footerData: IFooter;
}
export const menuColumns = "menu-columns";
export const itemTitle = "item-title";
declare global {
    interface Window {
        CookieInformation: any;
    }
}

export const Footer: FC<FooterProps> = ({ footerData }) => {
    const { get: getMicrocopy } = useMicrocopy();
    const customerService = clearCustomerServiceIcon(footerData?.fields?.customerService);
    const contactInformation = footerData?.fields?.contactInformation;
    const copyright = footerData?.fields?.copyright;
    const linkCollections = footerData?.fields?.linkCollections;
    const socialLinks = footerData?.fields?.socialLinks;
    const newsletterHeader = footerData?.fields?.newsletterHeader;
    const newsletterHeaderSuccess = footerData?.fields?.newsletterHeaderSuccess;
    const newsletterDescription = footerData?.fields?.newsletterDescription;
    const newsletterDescriptionSuccess = footerData?.fields?.newsletterDescriptionSuccess;
    const newsletterPolicyAgreement = footerData?.fields?.newsletterPolicyAgreement;
    const newsletterSubscribeButtonLabel = footerData?.fields?.newsletterSubscribeButtonLabel;
    const newsletterSubscribeButtonLabelSuccess =
        footerData?.fields?.newsletterSubscribeButtonLabelSuccess;
    const newsletterEmail = footerData?.fields?.newsletterEmail;
    const newsletterEmailInputPlaceholder = footerData?.fields?.newsletterEmailInputPlaceholder;

    const [countries, currentCountryId] = useCountriesSelector();

    const signUpNewsletter = {
        newsletterEmail,
        newsletterHeader,
        newsletterHeaderSuccess,
        newsletterDescription,
        newsletterDescriptionSuccess,
        newsletterPolicyAgreement,
        newsletterSubscribeButtonLabel,
        newsletterEmailInputPlaceholder,
        newsletterSubscribeButtonLabelSuccess,
    };

    return (
        <Box data-testid="footer" sx={styles.container} id="footer">
            <FooterEmailSubscription signUpNewsletter={signUpNewsletter} />
            <Divider sx={styles.divider} data-testid="divider" />
            <footer>
                <Flex px={[4, 8, 8]}>
                    <Flex sx={styles.mainContent}>
                        <Box sx={styles.leftPart}>
                            {!isEmpty(contactInformation) && (
                                <Box data-testid="contact-text" sx={styles.contactInformation}>
                                    {renderRichTextToComponent(contactInformation)}
                                </Box>
                            )}
                            <Box data-testid="custom-service" sx={styles.customerService}>
                                {renderRichTextToComponent(customerService)}
                            </Box>
                        </Box>
                        {!isEmpty(linkCollections) &&
                            linkCollections?.map((link, index) => (
                                <Box key={link?.sys?.id} sx={styles.group}>
                                    <Text
                                        sx={styles.groupCapture}
                                        data-testid="footer-group-title"
                                        textTransform="uppercase"
                                    >
                                        {link?.fields?.title}
                                    </Text>
                                    {index + 1 === linkCollections.length && (
                                        <Button
                                            variant="link"
                                            h="auto"
                                            key={link?.sys?.id}
                                            data-testid="renewcookie"
                                            sx={{ ...styles.link, lineHeight: "base" }}
                                            onClick={() => window.showCookieBanner()}
                                        >
                                            <span>
                                                {getMicrocopy(
                                                    CommonCMS.global,
                                                    CommonCMS.cookieRenew
                                                )}
                                            </span>
                                        </Button>
                                    )}
                                    {link?.fields?.links?.map((link) => (
                                        <Link
                                            key={link?.sys?.id}
                                            link={link}
                                            data-testid={itemTitle}
                                            sx={styles.link}
                                        >
                                            {link.fields.label}
                                        </Link>
                                    ))}
                                </Box>
                            ))}
                    </Flex>
                </Flex>
                <Flex sx={styles.socialMediaContainer}>
                    <Box sx={styles.authContainer}>
                        <AuthenticationActionBar />
                    </Box>
                    <SocialMediaWidget socialLinks={socialLinks} />
                </Flex>
                <Divider sx={styles.divider} data-testid="divider" />
                <Flex
                    px={[4, 8, 8]}
                    py={[3, 0]}
                    gap={[0, 4]}
                    justifyContent="space-between"
                    flexDirection={["column-reverse", "row"]}
                    alignItems={["flex-start", "center"]}
                >
                    <Flex sx={styles.privacyLocationContainer} data-testid="privacy-location">
                        <Box sx={styles.privacy} data-testid="footer-copyright-text">
                            {copyright}
                        </Box>
                    </Flex>
                    <Flex sx={styles.privacyLocationContainer}>
                        <CountrySelector
                            currentLocationId={currentCountryId}
                            locationList={countries}
                        />
                    </Flex>
                </Flex>
                {activeSiteHasLiveChat && (
                    <>
                        <LiveChatScript />
                        <LiveChat />
                    </>
                )}
            </footer>
        </Box>
    );
};

export default Footer;
