export enum ProductSegment {
    Footwear = "Footwear",
    LeatherGoods = "LeatherGoods",
    ShoeRelatedAccessories = "ShoeRelatedAccessories",
    Accessories = "Accessories",
}

export enum MaterialGroup {
    shoes = "Shoes",
    bags = "Bags",
    gloves = "Gloves",
    belts = "Belts",
    smallLeatherGoods = "Small Leather Goods",
    socks = "Socks",
    accessories = "Accessories",
    shoeSupport = "Shoe Support",
    shoeCare = "Shoe Care",
    boots = "Boots",
    sneakers = "Sneakers",
    slippers = "Slippers",
    sandals = "Sandals",
    inlaySoles = "Inlay Soles",
}

export const materialGroups = [
    "shoes",
    "boots",
    "bags",
    "gloves",
    "belts",
    "smallLeatherGoods",
    "socks",
    "accessories",
    "shoeSupport",
    "shoeCare",
    "sneakers",
    "slippers",
    "sandals",
    "inlaySoles",
];

export const GIFT_WRAPPING_KEY = "Giftwrap";
export const GIFT_WRAPPING_SKU = "8888881";
