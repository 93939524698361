import { GIFT_WRAPPING_KEY } from "@lib/constants/product";
import type { Cart } from "@graphql/generated/components";

export const getLineItemsQuantityWithoutGiftWrapping = (cart: Cart) => {
    if (cart?.lineItems?.some((item) => item.productKey === GIFT_WRAPPING_KEY)) {
        return cart.totalLineItemQuantity - 1;
    } else {
        return cart?.totalLineItemQuantity;
    }
};
