import type {
    EccoAddBillingAddressToMyCartMutationVariables,
    EccoAddDeliveryAddressToMyCartMutationVariables,
    EccoAddDeliveryDateAndTimeSlotToCartMutationVariables,
    EccoAddShippingMethodToCartMutationVariables,
    EccoUpdateLineItemSupplyChannelMutationVariables,
} from "@graphql/generated/components";
import {
    useEccoAddBillingAddressToMyCartMutation,
    useEccoAddDeliveryAddressToMyCartMutation,
    useEccoAddDeliveryDateAndTimeSlotToCartMutation,
    useEccoAddShippingMethodToCartMutation,
    useEccoUpdateLineItemSupplyChannelMutation,
} from "@graphql/generated/components";
import { updateMutation } from "../helpers";
import { useStore } from "../store";
import type { StoreState } from "@store/index";

const useCartStore = () => {
    const store = useStore<StoreState>();
    const setCartData = useStore((state) => state.setCart);
    return { store, setCartData };
};

export const useUpdateBillingAddress = () => {
    const [_, execute] = useEccoAddBillingAddressToMyCartMutation();
    const { setCartData } = useCartStore();
    return updateMutation<EccoAddBillingAddressToMyCartMutationVariables>(execute, setCartData);
};

export const useUpdateDeliveryAddress = () => {
    const [_, execute] = useEccoAddDeliveryAddressToMyCartMutation();
    const { setCartData } = useCartStore();
    return updateMutation<EccoAddDeliveryAddressToMyCartMutationVariables>(execute, setCartData);
};

export const useUpdateDeliveryDateAndTimeSlot = () => {
    const [_, execute] = useEccoAddDeliveryDateAndTimeSlotToCartMutation();
    const { setCartData } = useCartStore();
    return updateMutation<EccoAddDeliveryDateAndTimeSlotToCartMutationVariables>(
        execute,
        setCartData
    );
};

export const useAddShippingMethodToCart = () => {
    const [_, execute] = useEccoAddShippingMethodToCartMutation();
    const { setCartData } = useCartStore();
    return updateMutation<EccoAddShippingMethodToCartMutationVariables>(execute, setCartData);
};

export const useUpdateLineItemSupplyChannel = () => {
    const [_, execute] = useEccoUpdateLineItemSupplyChannelMutation();
    const { setCartData } = useCartStore();
    return updateMutation<EccoUpdateLineItemSupplyChannelMutationVariables>(execute, setCartData);
};
