import {
    categoryTitleMobileStyles,
    mobileButtonBackStyles,
    navItemMobileStyles,
    parentThumbnailContainerStyles,
    salesCategoryTitleMobileStyles,
    salesMobileButtonBackStyles,
    salesNavItemMobileStyles,
    seeAllCategoriesMobileStyles,
    thumbnailCarouselContainerStyles,
    thumbnailCarouselItemStyles,
} from "@components/layouts/navigation/styles";
import type { INavigationItem } from "@contentful-api/types/contentful";
import { Text } from "@ui/components/content/text/Text";
import { Button } from "@ui/components/forms/button/Button";
import { Box } from "@ui/components/layout/box/Box";
import { Container } from "@ui/components/layout/container/Container";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { useDisclosure } from "@ui/hooks/useDisclosure";
import { motion } from "framer-motion";
import { isEmpty } from "lodash-es";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { CategoryTile } from "../components/CategoryTile";
import { MobileSubNavItem } from "./MobileSubNavItem";
import {
    FIRST_LEVEL_NAVIGATION_BUTTON,
    MENU_DROPDOWN,
    SECOND_LEVEL_NAVIGATION_LINK,
} from "@e2e/helpers/navigation/constants";
export interface MobileNavItemProps {
    navigationItem: INavigationItem;
    handleFirstMenuBgColor: () => void;
    isSalesNavigationItem?: boolean;
}

export const MobileNavItem = ({
    navigationItem,
    handleFirstMenuBgColor,
    isSalesNavigationItem,
}: MobileNavItemProps) => {
    const { getButtonProps, getDisclosureProps, isOpen, onClose } = useDisclosure();
    const [hidden, setHidden] = useState(!isOpen);
    const { asPath } = useRouter();

    const handleMotionStart = (onAnimationStartStatus: boolean) => {
        handleFirstMenuBgColor();
        setHidden(onAnimationStartStatus);
    };

    const getMobileNavigationStyles = (isSalesNavigationItem: boolean) => ({
        navItemMobileStyles: isSalesNavigationItem ? salesNavItemMobileStyles : navItemMobileStyles,
        categoryTitleMobileStyles: isSalesNavigationItem
            ? salesCategoryTitleMobileStyles
            : categoryTitleMobileStyles,
        mobileButtonBackStyles: isSalesNavigationItem
            ? salesMobileButtonBackStyles
            : mobileButtonBackStyles,
        iconColor: isSalesNavigationItem ? "white" : "black",
    });
    useEffect(() => {
        const interval = setTimeout(() => {
            onClose();
        }, 1000);
        return () => clearTimeout(interval);
    }, [asPath, onClose]);

    const primaryLink = navigationItem?.fields?.primaryLink?.fields;
    const thumbnailLinks = navigationItem?.fields?.thumbnailLinks;
    const linkCollections = navigationItem?.fields?.linkCollections;
    const thumbnailLinksTiles = navigationItem?.fields?.thumbnailLinksTiles;
    const fallbackImage = navigationItem?.fields?.fallbackImage;
    const bottomLinkLabel = navigationItem?.fields?.bottomLinkLabel;

    return (
        /* First Level Navigation Mobile */
        <Flex direction="column" data-testid={primaryLink.pageTypeId}>
            <Flex
                data-testid={FIRST_LEVEL_NAVIGATION_BUTTON}
                as={Button}
                colorScheme="white"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                variant="solid"
                {...getButtonProps()}
                sx={getMobileNavigationStyles(isSalesNavigationItem).navItemMobileStyles}
            >
                <Text
                    sx={getMobileNavigationStyles(isSalesNavigationItem).categoryTitleMobileStyles}
                >
                    {primaryLink?.label.toUpperCase()}
                </Text>
                <ECCOIcon
                    aria-label="Right arrow"
                    name="chevron"
                    color={getMobileNavigationStyles(isSalesNavigationItem).iconColor}
                    transform="rotate(-90deg)"
                />
            </Flex>
            <motion.div
                {...getDisclosureProps()}
                hidden={hidden}
                initial={false}
                data-testid={MENU_DROPDOWN}
                onAnimationStart={() => handleMotionStart(false)}
                onAnimationComplete={() => setHidden(!isOpen)}
                animate={{ left: isOpen ? "0%" : "100%" }}
                transition={{ ease: "easeOut", duration: 0.15 }}
                align={"start"}
                style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    position: "absolute",
                    right: "0",
                    top: "0",
                    zIndex: 3,
                    width: "100%",
                    marginTop: 0,
                }}
            >
                {/* Second Level Navigation Mobile */}
                <Flex
                    as={Button}
                    colorScheme="white"
                    variant="solid"
                    sx={getMobileNavigationStyles(isSalesNavigationItem).mobileButtonBackStyles}
                    {...getButtonProps()}
                >
                    <ECCOIcon
                        aria-label="Right arrow"
                        name="chevron"
                        color={getMobileNavigationStyles(isSalesNavigationItem).iconColor}
                        transform="rotate(90deg)"
                    />
                    <Text fontSize="desktopHeading5" fontWeight={"semibold"}>
                        {primaryLink?.label.toUpperCase()}
                    </Text>
                </Flex>
                <Container
                    h="100%"
                    overflow={"auto"}
                    marginTop={"1px"}
                    bg={"white"}
                    px={"0"}
                    maxW={"100%"}
                >
                    {!isEmpty(thumbnailLinks) ? (
                        <Box sx={parentThumbnailContainerStyles} data-testid="ThumbnailLinks">
                            <Flex sx={thumbnailCarouselContainerStyles}>
                                <Flex sx={thumbnailCarouselItemStyles}>
                                    {thumbnailLinks?.map?.((link) => (
                                        <CategoryTile
                                            key={link?.sys?.id}
                                            link={link}
                                            isLeftSide={true}
                                            imageSizes={["200vw"]}
                                        />
                                    ))}
                                </Flex>
                            </Flex>
                        </Box>
                    ) : (
                        <>
                            {/* Third level navigation mobile */}
                            {bottomLinkLabel && (
                                <Flex
                                    data-testid={SECOND_LEVEL_NAVIGATION_LINK}
                                    as={NextLink}
                                    href={primaryLink?.completeSlug}
                                    sx={seeAllCategoriesMobileStyles}
                                >
                                    {bottomLinkLabel.toUpperCase()}
                                </Flex>
                            )}
                            {!isEmpty(linkCollections) &&
                                linkCollections?.map((linkCollection) => (
                                    <MobileSubNavItem
                                        key={linkCollection.sys?.id}
                                        title={linkCollection?.fields?.title.toUpperCase()}
                                        links={linkCollection?.fields?.links}
                                    />
                                ))}
                        </>
                    )}
                    <Flex flexDir={"column"} gap={8} justifyContent="space-between" pb={4} px={4}>
                        {!isEmpty(thumbnailLinksTiles) ? (
                            thumbnailLinksTiles?.map?.((link) => {
                                return (
                                    <CategoryTile
                                        key={link?.sys?.id}
                                        link={link}
                                        imageSizes={["200vw"]}
                                    />
                                );
                            })
                        ) : (
                            <NextImage
                                objectFit={"cover"}
                                layout={"responsive"}
                                src={fallbackImage?.fields?.file?.url}
                                alt="promo image"
                                sizes={["200vw"]}
                            />
                        )}
                    </Flex>
                </Container>
            </motion.div>
        </Flex>
    );
};
